<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="12">
        <el-col :span="cols[0]" :offset="0">选择奖品</el-col>
        <el-col :span="cols[1]" :offset="0">
          <div style="width: 100%; height: 40px;"></div>
        </el-col>
        <el-col :span="cols[2]" :offset="0">
          中奖概率
        </el-col>
        <el-col :span="cols[3]" :offset="0">数量（份） </el-col>
        <el-col :span="cols[4]" :offset="0">
          <!--  todo 新接口调用 原先名称账户余额 -->
          <div style="text-align: center">剩余</div>
        </el-col>
        <el-col :span="cols[5]" :offset="0">
          <div style="width: 100%; height: 40px;"></div>
        </el-col>
      </el-row>

      <div style="min-height: 206px;">
        <AddPrizeItem
          v-for="(prize, index) in prizes"
          :key="index"
          :cols="cols"
          :initialData="prize"
          ref="addPrizeItems"
          @deletePrize="handleClickDelete(index)"
          @change="(data) => handleItemChange(index, data)"
        />

        <div v-if="prizes.length === 0">
          <el-empty :image-size="60" description="暂无奖品"></el-empty>
        </div>
      </div>

      <el-divider></el-divider>

      <div class="footer-buttons">
        <el-button icon="el-icon-plus" type="text" @click="handleClickAdd">新增奖品</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import AddPrizeItem from "./add-prize-item.vue";

export default {
  components: {
    AddPrizeItem,
  },
  data() {
    return {
      prizes: [],
      cols: [4, 8, 3, 3, 3, 3],
    };
  },
  methods: {
    handleClickAdd() {
      this.prizes.push({
        prize_type: 1,
      });
    },
    handleClickDelete(index) {
      this.prizes = this.prizes.filter((_i, idx) => idx !== index);
    },
    handleItemChange(index, item) {
      this.prizes = this.prizes.map((prize, idx) => (index === idx ? item : prize));
      this.calculatePriority();
    },
    calculatePriority() {
      const total = this.prizes.reduce((prev, cur) => {
        const num = +cur.num || 0;
        return num + prev;
      }, 0);

      this.prizes = this.prizes.map((prize) => {
        let probability = "";
        if (+prize.num) {
          probability = ((+prize.num / total) * 100).toFixed(2);
        }
        return {
          ...prize,
          probability,
        };
      });
    },
    getData() {
      return [...this.prizes];
    },
    setData(data) {
      this.prizes = [...data];
    },
    validate() {
      const addPrizeItemRefs = this.$refs.addPrizeItems || [];
      for (let i = 0; i < addPrizeItemRefs.length; i++) {
        const element = addPrizeItemRefs[i];
        element.validate();
      }
    },
  },
};
</script>

<style lang="less">
.footer-buttons {
  display: flex;
  justify-content: center;
}
</style>
