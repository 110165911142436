<template>
  <el-dialog
    destroy-on-close
    :close-on-click-modal="false"
    top="40px"
    title="抽奖选项设置"
    :visible.sync="visible"
    width="560px"
    @close="close"
  >
    <h3>抽奖背景图更换</h3>
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <UploadBg @change="handleChangeUrl1" title="全民场背景图" ref="uploadBg1" />
      </el-col>
      <el-col :span="12" :offset="0">
        <UploadBg @change="handleChangeUrl2" title="合成图场背景图" ref="uploadBg2" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <h3>奖品类型属性设置</h3>
    <el-row :gutter="20" style="margin-bottom: 16px;">
      <el-col :span="12" :offset="0">
        <UploadBg @change="handleChangeUrl3" title="积分背景图" ref="uploadBg3" />
      </el-col>
      <el-col :span="12" :offset="0">
        <UploadBg @change="handleChangeUrl4" title="谢谢参与背景图" ref="uploadBg4" />
      </el-col>
    </el-row>

    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <el-form-item label="每个用户24小时内参与抽奖次数" prop="daily_draw_limit">
        <el-input-number
          :step="1"
          step-strictly
          :controls="false"
          :min="1"
          v-model="form.daily_draw_limit"
          class="form-number-input"
          placeholder="请输入次数"
          clearable
        ></el-input-number>
      </el-form-item>
      <el-form-item label="钱包账户" prop="prize_account_ucid">
        <el-input
          placeholder="请输入发放奖品账户钱包账户"
          v-model="form.prize_account_ucid"
        ></el-input>
      </el-form-item>
      <el-form-item label="钱包索引" prop="prize_account_user_index">
        <el-input
          placeholder="请输入发放奖品账户钱包索引"
          v-model="form.prize_account_user_index"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <div class="join-condition-label">
            <div style="margin-right: 12px;">参与条件（合成图）设置</div>
            <JoinConditions @add-nft="onAddNFT" />
          </div>
        </template>
        <div v-for="nft in nftList" :key="nft.id">
          <el-row :gutter="12">
            <el-col :span="8" :offset="0">
              <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                {{ nft.name }}
              </div>
            </el-col>
            <el-col :span="12" :offset="0">
              <CopyTxid justify="start" :txid="nft.id" />
            </el-col>
            <el-col :span="4" :offset="0">
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="small"
                @click="() => handleDelete(nft.id)"
              ></el-button>
            </el-col>
          </el-row>
          <el-divider class="join-condition-divider"></el-divider>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import JoinConditions from "./components/join-conditions.vue";
import CopyTxid from "@/components/CopyTxid.vue";
import UploadBg from "./components/upload-bg.vue";
import { getLuckydrawConfig, setLuckydrawConfig } from "@/api/nft-lucky-draw.js";

export default {
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        prize_account_user_index: [{ required: true, message: "请输入", trigger: "change" }],
        prize_account_ucid: [{ required: true, message: "请输入", trigger: "change" }],
        daily_draw_limit: [{ required: true, message: "请输入", trigger: "change" }],
      },
      nftList: [],
      hecheng_background_url: "",
      points_prize_url: "",
      quanmin_background_url: "",
      thanks_prize_url: "",
      // 任意合成图
      any_advanced_nft: null,
    };
  },
  computed: {
    hideQuanminUploadButton() {
      return !!this.form.quanmin_background_url;
    },
  },
  methods: {
    close() {
      this.visible = false;
    },
    async open() {
      this.visible = true;
      this.fetchConfigs();
      await this.$nextTick();
      this.$refs.uploadBg1.setFileList("https://images.ddpurse.com/upload/16748727859522455.png");
    },
    async fetchConfigs() {
      try {
        const { data } = await getLuckydrawConfig();
        if (data?.data) {
          // 四个背景图
          this.quanmin_background_url = data.data.quanmin_background_url || "";
          this.$refs.uploadBg1.setFileList(this.quanmin_background_url);

          this.hecheng_background_url = data.data.hecheng_background_url || "";
          this.$refs.uploadBg2.setFileList(this.hecheng_background_url);

          this.points_prize_url = data.data.points_prize_url || "";
          this.$refs.uploadBg3.setFileList(this.points_prize_url);

          this.thanks_prize_url = data.data.thanks_prize_url || "";
          this.$refs.uploadBg4.setFileList(this.thanks_prize_url);

          // 其他配置
          this.form = {
            prize_account_user_index: +data.data.prize_account_user_index, // 奖品账号钱包
            prize_account_ucid: data.data.prize_account_ucid, // 奖品账号ucid
            daily_draw_limit: data.data.daily_draw_limit, // 抽奖上限
          };
          // 任意合成图
          this.any_advanced_nft = data.data.any_advanced_nft;
          this.setNFTList(data.data.any_advanced_nft);
        }
      } catch (error) {}
    },
    setNFTList(data) {
      if (!data) {
        return;
      }
      const list = Object.keys(data).map((key) => {
        return {
          name: data[key],
          id: key,
        };
      });
      this.nftList = list;
    },
    onAddNFT(nftData) {
      const txids = this.nftList.map((i) => i.id);
      if (!txids.includes(nftData.id)) {
        this.nftList.push(nftData);
      } else {
        this.$message.info("");
      }
    },
    onUploadSuccess(key, res) {
      if (key === "quanmin" && res && res.data) {
        this.form = {
          ...this.form,
          quanmin_background_url: res.data,
        };
        console.log(this.form);
      }
    },
    onRemove(key) {
      if (key === "quanmin") {
        this.form = {
          ...this.form,
          quanmin_background_url: "",
        };
      }
    },
    handleDelete(id) {
      this.nftList = this.nftList.filter((item) => item.id !== id);
    },
    validate() {
      // let isSuccess = true;
      if (
        !this.hecheng_background_url ||
        !this.quanmin_background_url ||
        !this.points_prize_url ||
        !this.thanks_prize_url
      ) {
        this.$message.error("请上传背景图");
        return false;
      }
      if (!this.nftList || this.nftList.length === 0) {
        this.$message.error("请设置参与条件（合成图）");
        return false;
      }
      return true;
    },
    async onSubmit() {
      if (!this.validate()) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      try {
        const nftids = this.nftList.map((item) => item.id).join(",");
        const postData = {
          ...this.form,
          prize_account_user_index: +this.form.prize_account_user_index,
          hecheng_background_url: this.hecheng_background_url,
          points_prize_url: this.points_prize_url,
          quanmin_background_url: this.quanmin_background_url,
          thanks_prize_url: this.thanks_prize_url,
          any_advanced_nft: nftids,
        };
        await setLuckydrawConfig(postData);
        this.$message.success("操作成功");
        this.close();
      } catch (error) {
        console.log(error);
      }
    },
    handleCancel() {
      this.close();
    },
    handleChangeUrl1(url) {
      this.quanmin_background_url = url;
    },
    handleChangeUrl2(url) {
      this.hecheng_background_url = url;
    },
    handleChangeUrl3(url) {
      this.points_prize_url = url;
    },
    handleChangeUrl4(url) {
      this.thanks_prize_url = url;
    },
  },
  components: { JoinConditions, CopyTxid, UploadBg },
};
</script>

<style lang="less">
.form-number-input.el-input-number {
  width: 100%;
  .el-input__inner {
    text-align: left;
  }
}
.join-condition-label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.join-condition-divider {
  margin: 8px 0;
}
</style>
