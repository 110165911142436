<template>
  <div>
    <el-button size="mini" @click="handleClickAdd" icon="el-icon-plus" circle> </el-button>
    <el-dialog
      class="add-condition-dialog"
      title="新增条件（合成图）"
      :visible.sync="visible"
      width="30%"
      @close="close"
      append-to-body
    >
      <span>
        <el-form
          :model="form"
          ref="txForm"
          :rules="rules"
          label-width="60px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="TXID" prop="txid" :error="noNFTError">
            <el-input
              v-model="form.txid"
              placeholder="请输入TXID"
              size="normal"
              clearable
              @change="onChangeTxid"
            >
              <el-button slot="append" icon="el-icon-search" @click="onQueryNFT"></el-button>
            </el-input>
          </el-form-item>
        </el-form>

        <div style="padding-left: 60px;" v-if="nftData">
          {{ nftData.name }} | 铸造: {{ nftData.total }} 份
        </div>
      </span>
      <span slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleAddCondition">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getNFTInfoByTxid } from "@/api/nft-attribute.js";

export default {
  data() {
    return {
      list: [],
      visible: false,
      nftData: null,
      form: { txid: "" },
      rules: {
        txid: [{ required: true, message: "请输入有效TXID", trigger: "change" }],
      },
      noNFTError: "",
    };
  },
  methods: {
    handleAddCondition() {
      this.$refs.txForm.validate((valid) => {
        if (valid) {
          if (!this.nftData) {
            this.noNFTError = "请输入有效TXID";
          } else {
            this.submit(this.nftData);
          }
        } else {
          return false;
        }
      });
    },
    submit(nft) {
      this.$emit("add-nft", nft);
      this.close();
    },
    close() {
      this.visible = false;
      this.form = { txid: "" };
      this.nftData = null;
    },
    async handleClickAdd() {
      this.visible = true;
      await this.$nextTick();
      this.$refs.txForm.clearValidate("txid");
    },
    onChangeTxid() {
      this.nftData = null;
    },
    async onQueryNFT() {
      if (!this.form.txid) {
        this.$message.error("请先输入TXID");
        return;
      }
      const res = await getNFTInfoByTxid(this.form.txid);
      if (res.items && res.items[0]) {
        this.nftData = res.items[0];
      } else {
        this.$message.error("请先正确的TXID");
      }
    },
  },
};
</script>

<style lang="less">
.add-condition-dialog {
  z-index: 1000;
}
</style>
