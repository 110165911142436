<template>
  <div>
    <el-dialog title="中奖名单" :visible.sync="visible" width="1180px" @close="close">
      <div>
        <el-table :data="list" border stripe>
          <el-table-column prop="name" label="藏品"></el-table-column>
          <el-table-column prop="txid" label="TXID"> </el-table-column>
          <el-table-column prop="user_name" label="中奖人昵称"> </el-table-column>
          <el-table-column prop="ucid" label="UCID"> </el-table-column>
          <el-table-column prop="mobile" label="手机号"> </el-table-column>
          <el-table-column prop="email" label="邮箱"> </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px; text-align: right;"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLuckyUses } from "@/api/nft-lucky-draw.js";

export default {
  data() {
    return {
      list: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 20,
      visible: false,
      id: "",
    };
  },

  methods: {
    async fetchList(id) {
      this.loading = true;
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
          activity_id: id,
        };
        const { data } = await getLuckyUses(params);
        this.list = data.data.items;
        this.total = data.data.total;
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
    close() {
      this.visible = false;
    },
    open(id) {
      this.visible = true;
      this.id = id;
      this.fetchList(id);
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList(this.id);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList(this.id);
    },
  },
};
</script>

<style lang="less" scoped></style>
