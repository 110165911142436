<template>
  <el-row :gutter="12" style="margin-bottom: 20px">
    <el-col :span="cols[0]" :offset="0">
      <el-select v-model="form.prize_type" @change="handleChangeType">
        <el-option
          v-for="item in prizeTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="cols[1]" :offset="0">
      <el-input-number
        v-if="form.prize_type === 1"
        placeholder="请输入奖励积分"
        v-model="form.value"
        :step="1"
        step-strictly
        :controls="false"
        :max="99999"
        :min="1"
        class="points-input"
        :class="{ 'error-input': !!valueError }"
        @change="handlePointsChange"
      >
      </el-input-number>

      <template v-else-if="form.prize_type === 2">
        <el-input
          v-model="form.value"
          placeholder="请输入TXID"
          size="normal"
          clearable
          @change="handleTXIDChange"
        >
        </el-input>
        <div v-if="nftData && nftData.name">
          《{{ nftData.name }}》 | 持有：{{ nftData.own_num }}
        </div>
      </template>
      <el-input v-else size="normal" disabled></el-input>
      <div v-if="!!valueError" class="error-tip">{{ valueError }}</div>
    </el-col>
    <el-col :span="cols[2]" :offset="0">
      <el-input :value="initialData.probability" size="normal" disabled>
        <template slot="suffix">
          <div style="padding-right: 8px;">%</div>
        </template>
      </el-input>
    </el-col>
    <el-col :span="cols[3]" :offset="0">
      <el-input-number
        v-model="form.num"
        :step="1"
        step-strictly
        :controls="false"
        :min="1"
        @change="handleChangeNum"
        class="points-input"
        :class="{ 'error-input': !!numError }"
      ></el-input-number>

      <div class="error-tip" v-if="numError">{{ numError }}</div>
    </el-col>
    <el-col :span="cols[4]" :offset="0">
      <div style="text-align: center;">
        {{ remaining || 0 }}
      </div>
    </el-col>
    <el-col :span="cols[5]" :offset="0">
      <el-button icon="el-icon-delete" type="danger" @click="handleClickDelete" size="small">
        删除
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import { options } from "../config";
// import { getNFTInfoByTxid } from "@/api/nft-attribute.js";
import { getNFTNameByTxid } from "@/api/nft.js";
export default {
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      valueError: "",
      numError: "",
      prizeTypeOptions: options.prizeType,
      nftData: null,
      remaining: null,
    };
  },
  mounted() {
    this.form = {
      ...this.initialData,
    };
    this.remaining = +this.initialData.surplus || 0;
  },
  methods: {
    handleClickDelete() {
      this.$emit("deletePrize");
    },
    handleChangeType(val) {
      // 奖品类型修改，清空积分和数量
      this.form = {
        ...this.form,
        value: val === 3 ? "" : undefined,
        num: undefined,
      };
      this.remaining = null;
      this.nftData = null;
      this.numError = "";

      this.emitChange();
    },
    handlePointsChange() {
      this.valueError = "";
      this.emitChange();
    },
    emitChange() {
      this.$emit("change", {
        ...this.form,
        value: this.form.value ? `${this.form.value}` : "",
      });
    },

    handleChangeNum(val) {
      this.numError = "";
      // 藏品
      if (this.form.prize_type === 2 && this.remaining != null) {
        this.numError = +val > this.remaining ? "已超剩余份数" : "";
      }
      // if (this.form.prize_type !== 2) {
      this.remaining = val;
      // }
      this.emitChange();
    },
    handleTXIDChange(val) {
      this.valueError = "";
      this.onQueryNFT(val);
    },
    async onQueryNFT(txid) {
      if (!txid) {
        this.$message.error("请输入TXID");
        this.nftData = null;
        this.remaining = 0;
        return;
      }
      try {
        const res = await getNFTNameByTxid({ txid, source: "points_draw" });
        if (res?.data?.data && res?.data?.data.name) {
          this.nftData = res?.data?.data;
          // this.remaining = res?.data?.data.own_num;
        } else {
          // this.$message.error("请输入正确的TXID");
          this.valueError = "请输入正确的TXID";
          this.nftData = null;
          this.remaining = 0;
        }
      } catch (error) {
        this.nftData = null;
        this.remaining = 0;
      }

      this.emitChange();
    },
    validate() {
      // 积分
      if (this.form.prize_type === 1) {
        this.valueError = !this.form.value ? "请输入奖励积分" : "";
        this.numError = !this.form.num ? "请输入数量" : "";
        return !this.valueError && !this.numError;
      } else if (this.form.prize_type === 2) {
        // 藏品
        this.numError = !this.form.num ? "请输入数量" : "";
        if (!this.form.value) {
          this.valueError = "请输入TXID";
        } else {
          if (!this.nftData) {
            this.valueError = "请输入正确的TXID";
          } else {
            this.numError = +this.form.num > this.remaining ? "已超剩余份数" : "";
          }
        }
        return !this.valueError && !this.numError;
      } else {
        // 谢谢参与
        if (!this.form.num) {
          this.numError = "请输入数量";
        }
        return !this.numError;
      }
    },
  },
};
</script>

<style lang="less">
.points-input.el-input-number {
  width: 100%;
  .el-input__inner {
    text-align: left;
  }
}
.error-tip {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
.error-input {
  .el-input__inner {
    border-color: #f56c6c;
  }
}
</style>
