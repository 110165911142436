<template>
  <div class="lucky-draw-page" style="padding: 20px;">
    <div class="lucky-draw-header" style="padding-bottom: 20px;">
      <el-button type="primary" size="default" @click="handleClickAdd" icon="el-icon-plus">
        新增抽奖
      </el-button>
      <el-button
        type="primary"
        size="default"
        @click="handleClickSetOptions"
        icon="el-icon-s-tools"
      >
        抽奖选项设置
      </el-button>
    </div>
    <el-table :data="list" border stripe>
      <el-table-column prop="activity_id" label="活动ID"></el-table-column>
      <el-table-column prop="draw_type_desc" label="抽奖类型"> </el-table-column>
      <el-table-column prop="consume_points" label="每次消耗积分"> </el-table-column>
      <el-table-column prop="condition_desc" label="参与条件"> </el-table-column>
      <el-table-column prop="enable_desc" label="抽奖状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.enable === 0 ? 'info' : 'success'">
            {{ scope.row.enable_desc }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="total_prize_points" label="累计已发放(积分)"> </el-table-column>
      <el-table-column prop="total_consume_points" label="累计消耗(积分)"> </el-table-column> -->
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-if="scope.row.enable === 0"
            type="primary"
            @click="handleOpen(scope.row)"
          >
            开启活动
          </el-button>
          <el-button size="mini" type="info" v-else @click="handleClose(scope.row)">
            关闭活动
          </el-button>
          <el-button
            size="mini"
            type="success"
            @click="handleClickSettings(scope.row)"
            icon="el-icon-edit"
          >
            设置
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleClickLuckyUsers(scope.row)"
            icon="el-icon-user"
          >
            中奖名单
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right;"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <LuckyUsers ref="luckyUserDialog" />
    <LuckyDrawAdd ref="luckyDrawAdd" @add-success="handleAddSuccess" />
    <OptionsSetting ref="optionsSetting" />
  </div>
</template>

<script>
import { getLuckyDrawList, toggleLuckydrawStatus } from "@/api/nft-lucky-draw.js";
import LuckyUsers from "./lucky-users.vue";
import LuckyDrawAdd from "./add.vue";
import OptionsSetting from "./options-setting.vue";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      loading: false,
      currentItem: null,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      this.list = [];
      this.loading = true;
      try {
        const { data } = await getLuckyDrawList();
        this.list = data.data.items;
        this.total = data.data.total;
      } catch (error) {
        console.log(error);
      }
      this.loading = true;
    },
    async handleOpen(item) {
      this.toggleEnable({
        activity_id: item.activity_id,
        enable: 1,
      });
    },
    handleClose(item) {
      this.toggleEnable({
        activity_id: item.activity_id,
        enable: 0,
      });
    },
    async toggleEnable(data) {
      try {
        await toggleLuckydrawStatus(data);
        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },
    handleClickSettings(item) {
      this.$refs.luckyDrawAdd?.openEdit({ ...item });
    },
    handleClickLuckyUsers(item) {
      this.currentItem = item;
      if (item.activity_id) {
        this.$refs.luckyUserDialog?.open(item.activity_id);
      }
    },
    handleClickSetOptions() {
      this.$refs.optionsSetting?.open();
    },
    handleClickAdd() {
      this.$refs.luckyDrawAdd?.open();
    },
    handleAddSuccess() {
      this.$refs.luckyDrawAdd?.close();
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList();
    },
  },
  components: { LuckyUsers, LuckyDrawAdd, OptionsSetting },
};
</script>

<style lang="less" scoped>
.lucky-draw-header {
  display: flex;
  justify-content: flex-end;
}
</style>
