<template>
  <div>
    <div style="margin-bottom: 12px;">{{ title }}</div>
    <el-upload
      :action="uploadAction"
      list-type="picture-card"
      :on-success="onUploadSuccess"
      :on-remove="onRemove"
      :on-progress="onChange"
      :file-list="fileList"
      :limit="1"
      class="upload-bg-container"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uploadAction: "https://www.ddpurse.com/uploadImage",
      fileList: [],
      url: "",
    };
  },
  methods: {
    onUploadSuccess(res) {
      if (res && res.data) {
        this.url = res.data;
        this.$emit("change", this.url);
      }
    },
    onRemove() {
      this.url = "";
      this.$emit("change", this.url);
    },
    onChange(file, fileList) {
      console.log(file, fileList);
    },
    setFileList(url) {
      this.fileList = [
        {
          name: "bg.png",
          url,
        },
      ];
      this.url = url;
      this.$emit("change", url);
    },
  },
  computed: {
    hideUploadButton() {
      return !!this.url;
    },
  },
};
</script>

<style lang="less">
// .is-hide-button {
//   .el-upload.el-upload--picture-card {
//     display: none;
//   }
// }
.upload-bg-container {
  height: 148px;
  overflow: hidden;
}
</style>
