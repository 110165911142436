<template>
  <div>
    <el-dialog
      :title="isEdit ? '设置详情' : '新增抽奖'"
      :visible.sync="visible"
      width="1180px"
      @close="close"
      destroy-on-close
    >
      <div class="add-form-container">
        <el-form
          :model="form"
          ref="addLuckdrawForm"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="抽奖类型" prop="draw_type">
            <el-select v-model="form.draw_type">
              <el-option
                v-for="item in options.drawType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="活动状态" style="display: none;">
            <el-switch v-model="form.enable" :active-value="1" :inactive-value="0"> </el-switch>
          </el-form-item>

          <el-form-item label="参与条件" prop="condition">
            <el-select v-model="form.condition">
              <el-option
                v-for="item in options.condition"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="积分消耗" prop="consume_points">
            <div class="consume-points">
              <span>每次抽奖需消耗</span>
              <el-input-number
                v-model="form.consume_points"
                :step="1"
                step-strictly
                :controls="false"
                :max="99999"
                :min="1"
                class="consume-points-input"
                size="normal"
              ></el-input-number>
              <span>积分</span>
              <span v-if="isEdit">,累计消耗积分:{{ activity_statistic.total_consume_points }}</span>
            </div>
          </el-form-item>

          <el-form-item label="奖品设置">
            <AddPrizes ref="addPrizesCard" />
          </el-form-item>
        </el-form>

        <div></div>
      </div>
      <span slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { options } from "./config";
import AddPrizes from "./components/add-prizes.vue";
import { addLuckydraw, getLuckydrawStatistic } from "@/api/nft-lucky-draw.js";

export default {
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        draw_type: [{ required: true, message: "请选择抽奖类型", trigger: "change" }],
        condition: [{ required: true, message: "请选择参与条件", trigger: "change" }],
        consume_points: [{ required: true, message: "请输入积分消耗", trigger: "change" }],
      },
      options: options,
      isEdit: false,
      editData: null,
      activity_statistic: {},
    };
  },
  methods: {
    close() {
      this.form = {};
      this.$refs.addLuckdrawForm.clearValidate();
      this.visible = false;
      this.isEdit = false;
      this.editData = null;
    },
    async open() {
      this.visible = true;
      await this.$nextTick();
      this.$refs.addLuckdrawForm.clearValidate();
      this.$refs.addPrizesCard.setData([]);
    },
    async openEdit(data) {
      this.editData = data;
      this.form = {
        draw_type: data.draw_type,
        enable: data.enable,
        condition: data.condition,
        consume_points: data.consume_points,
      };
      this.isEdit = true;
      this.visible = true;
      await this.$nextTick();
      this.getStatics(data.activity_id);
      // const prizes = data.prize.map((item) => {
      //   return {
      //     num: item.num,
      //     prize_type: item.prize_type,
      //     value: item.value,
      //     probability: item.probability,
      //     surplus: item.surplus,
      //   };
      // });
      // this.$refs.addPrizesCard.setData(prizes);
    },
    async getStatics(id) {
      try {
        const res = await getLuckydrawStatistic({ activity_id: id });
        let prizeData = res?.data?.data;
        this.activity_statistic = prizeData;
        const prizes = prizeData.prize.map((item) => {
          return {
            num: item.num,
            prize_type: item.prize_type,
            value: item.value,
            probability: item.probability,
            surplus: item.surplus,
          };
        });
        this.$refs.addPrizesCard.setData(prizes);
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      const prize = this.$refs.addPrizesCard.getData();
      const postData = {
        ...this.form,
        prize: prize,
      };
      if (this.isEdit) {
        postData["activity_id"] = this.editData.activity_id;
      }
      try {
        await addLuckydraw(postData);
        this.$emit("add-success");
      } catch (error) {
        console.log(error);
      }
    },
    validatePrizes() {
      this.$refs.addPrizesCard.validate();
    },
    async handleConfirm() {
      this.validatePrizes();
      this.$refs.addLuckdrawForm.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
  },
  components: {
    AddPrizes,
  },
};
</script>

<style lang="less">
.consume-points {
  display: flex;
  align-items: center;

  .el-input {
    width: 80px;
    margin: 0 8px;
  }
}
.consume-points-input.el-input-number {
  width: 96px;
}
</style>
