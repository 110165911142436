export const options = {
  drawType: [
    {
      value: 1,
      label: "全民场",
    },
    {
      value: 2,
      label: "合成图场",
    },
  ],

  condition: [
    {
      value: 1,
      label: "无条件",
    },
    {
      value: 2,
      label: "任意合成图",
    },
  ],

  prizeType: [
    {
      value: 1,
      label: "积分",
    },
    {
      value: 2,
      label: "藏品",
    },
    {
      value: 3,
      label: "谢谢参与",
    },
  ],
};
