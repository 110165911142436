import config from "@/configs/index";
import axios from "axios";

const host = config.url;
// const host = "https://mock.apifox.cn/m1/1524419-0-59be01ab";

// 抽奖活动列表
export function getLuckyDrawList(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/points_draw_activity_list",
    method: "post",
    data: postData,
  });
}

// 中奖名单
export function getLuckyUses(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/points_draw_winner_list",
    method: "post",
    data: postData,
  });
}

// 新增抽奖活动
export function addLuckydraw(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/add_or_set_points_draw_activity",
    method: "post",
    data: postData,
  });
}

// 获取抽奖选项配置信息
export function getLuckydrawConfig(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/get_points_draw_activity_config",
    method: "post",
    data: postData,
  });
}

// 设置抽奖选项
export function setLuckydrawConfig(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/set_points_draw_activity_config",
    method: "post",
    data: postData,
  });
}

export function toggleLuckydrawStatus(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/update_points_draw_activity_status",
    method: "post",
    data: postData,
  });
}

export function getLuckydrawStatistic(postData) {
  return axios({
    url: host + "/phoenix/admin_nft_order/points_draw_activity_statistic",
    method: "post",
    data: postData,
  });
}
